import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/packages/recipes/src/layouts/recipe.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h5>{`Prep time: 30 min. `}<meta itemProp="prepTime" content="PT30M" /></h5>
    <h5>{`Cook time: 2 hr. `}<meta itemProp="cookTime" content="PT2H" /></h5>
    <h5>{`Servings: `}<span itemProp="recipeYield">{`6`}</span></h5>
    <hr></hr>
    <h2>{`Ingredients`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Bacon, 4 slices</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Butter, 1 tbs. optional</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Tomato paste, 3 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Onion, 1 chopped</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Celery, 1 cup chopped</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Carrots, 1 cup chopped</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Garlic, 3 cloves chopped</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Ground turkey, 1 lbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Black pepper, to taste</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Bay leaves, 2</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Basil, 1 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Oregano, 1 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Fish sauce, 1 tbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Whole milk, 1 cup</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Canned diced tomatoes, 28 oz.</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Equipment`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="tool">Large pot</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Prep`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="step">Thaw turkey</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Dice bacon</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Chop onion</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Chop celery</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Chop carrots</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Chop garlic</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Instructions`}</h2>
    <span itemProp="recipeInstructions">
      <ol>
        <li parentName="ol">
          <p parentName="li">{`Cook bacon on medium high heat until slightly cripsy.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`If there isn't enough grease, supplement with butter.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add tomato paste and stir.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add onion and cook until transparent.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add celery, carrots, and garlic. Cook for 2 minutes.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add ground turkey and mix until mixture is even.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add black pepper, bay leaves, basil, oregano, and fish sauce. Cook until meat browns.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add milk and simmer until it cooks down.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add diced tomatoes and simmer down.`}</p>
        </li>
      </ol>
    </span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      